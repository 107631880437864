body{
    font-family: 'DM Sans' !important;
}
.modal-content{
    background-color: rgb(41,46,53);
    width: 62%;
    margin-left: 75px;
    margin-top: 80px;
}

.request-model .modal-content{
    background-color: rgb(41,46,53);
    width: fit-content;
    margin-left: 0px;
    margin-top: 70px;
}

.login-header h1{
    justify-content: center;
    color: #FFF3F3;
    font-size: 2rem !important;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-align: center;
    font-family: 'DM Sans' !important;
}
.login-header h1 span{
    color:#EAAB4E;
    font-family: 'DM Sans' !important;
}
.modal-header{
    border-bottom: none;
    height: 1px;
    font-family: 'DM Sans' !important;
}
.btn-close{
    --bs-btn-close-bg: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAx0lEQVR4nO2UUQrCMAxA6wWdDmUfenaFiVOZN1B4UuhQNJ1Ntypi318hyaNJWmMymb8BWAJnYA/MFHlz4AC0wEIrnbjEjgtQBeRVLrajVYktwOmhwFu5ILUcjRagFAqJco/Unku12GJnJBS8AuunXZBiVmYI9MiTSQNaGTSKQXhunuamCnk6qcUz05eF+5Q0nZxvLBc9TybknY8uNfeYceVAMcKXWcSId5r5eeR1jLjRLo0gb2JbXQMbTctc3tblTtXiTOZnuQEG7QtfZaLvigAAAABJRU5ErkJggg==') !important;
    --bs-btn-close-opacity:1;
}
.loginP{
    text-align: center;
    font-size: 12px;
    color: #FFF3F3;
    letter-spacing: 0.2px;
    font-family: 'DM Sans' !important;    
}
.g-btn{
    background-color: transparent !important;
    border: 1px solid whitesmoke !important;
    border-radius: 13px !important;
    width: 100% !important;
    height: 40px !important;
    font-size: 15px !important;
    font-weight: 400;
    font-family: 'DM Sans' !important;
}
.g-btn div{
    background-color: transparent !important;
    width: 38px !important;
    height: 50px !important;
    margin-top: -4px !important;
    font-family: 'DM Sans' !important;
}
form input{
    background-color: transparent !important;
    color: whitesmoke !important;
    font-family: 'DM Sans' !important;
}
::-ms-input-placeholder { /* Edge 12-18 */
    color: whitesmoke !important;
    font-size: 12px;
    font-family: 'DM Sans' !important;
}
::placeholder {
    color:whitesmoke !important;
    opacity: 1; /* Firefox */
    font-size: 12px;
    font-family: 'DM Sans' !important;
}
.log-submit button{
    background-color: #434343;
    color: antiquewhite;
    border: 1px solid #434343;
    border-radius: 15px;
    letter-spacing: 0.3;
    font-family: 'DM Sans' !important;
    font-weight: 500;
}
.home-back{
    color:whitesmoke;
    float: left;
    font-size: 18px;
    border: none;
}
.home-back FontAwesomeIcon{
    color: whitesmoke;
}
.home-back:hover{
    color:whitesmoke;
    border: none;
}
.home{
    color: whitesmoke;
    text-align: left;
}
.home .logo{
    position: relative;
}
.home .logo img {
    float: left;
}
.home .logo h3{
    margin-top: 20px;
    padding-top: 15px;
}
.home .rating{
    color:#EAAB4E;
    margin-top: -9px;
    font-size: 17px;
}
.login-name:hover{
    background-color:#434343;
    color: whitesmoke;
}
.product-details .logo img{
    width: 80px;
    height: 80px;
    padding: 10px;
}
.scrollable-images img{
    width: 15%;
    margin-right: 40px;
}

.scroll-button .fa-angle-right {
    background: white;
    padding: 12px;
    border-radius: 25px;
    margin-left: -21px;
    width: 15px;
    height: 16px;
}
.right{
    margin-right: -12px;
}
.left{
    margin-left: -3px;
}
.scroll-button .fa-angle-left {
    background: white;
    padding: 12px;
    border-radius: 25px;
    margin-right: -20px;
    width: 15px;
    height: 16px;
}
.request-model iframe{
    width: 640px;
}
.product-images{
    border-radius: 12px;
    margin: 12px;
    background-color: #2b3139;
    padding: 16px;
    width: 22.8%;
}
.product-images img{
    width: 100%;
}
#product-name{
    color: white;
    line-height: 13px;
    font-size: 15px;
    padding-top: 10px;
    margin-bottom: 0rem;
    text-align: left;
    padding-left: 3px;
    font-weight: 600;
    letter-spacing: 1.2px;
}
#product-subname{
    color: white;
    line-height:13px;
    font-size: 10px;
    padding-top: 3px;
    margin-bottom: 0rem;
    text-align: left;
    padding-left: 3px;
}
.product-subimage{
    width: 30% !important;
    padding-right: 9px;
    border-radius: 25px;
    float: left;
    padding-left: 5px;
    padding-top: 5px;
}
@media screen and (max-width: 480px) {
    .modal-content{
        width: 100%;
        margin-left: 0px;
    }
    .home .logo h3 {
        margin-top: 0px;
        padding-top: 5px;
    }
    .scrollable-images img{
        width: 35%;
    }
    .scroll-button .fa-angle-right {
        margin-left: -16px; 
    }
    .left {
        margin-left: -19px; 
    }
    .home{
        text-align: center;
    }
    .request-model iframe{
        width: 100%;
    }
    .request-model .modal-content{
        width:auto;
    }
    .product-images img{
        width: -webkit-fill-available;
    }
    .product-images{
        margin: 0px;
        margin-bottom: 30px;
        width: 100%;
    }
    .product-row{
        padding: 23px;
    }
    #product-name{
        color: white;
        line-height: 23px;
        font-size: 21px;
        padding-top: 13px;
        margin-bottom: 0rem;
        text-align: left;
        padding-left: 3px;
    }
    .product-subimage{
        width: 26% !important;
        padding-left: 8px;
    }
    #product-subname{
        font-size: 13.8px;
    }
}